import axios from '@/plugins/axios';

export const checkAuth = async (key: string): Promise<any> => (
  new Promise((resolve, reject) => {
    axios.post('auth/telegram/key', { key })
      .then((res) => {
        localStorage.setItem('accessToken', res.data.token);
        resolve(true);
      })
      .catch((e) => {
        reject(e.response);
      });
  })
);
